import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AsyncPipe, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {AppRoutingModule} from './app.routing.module';
import {ExportService} from './service/export.service';

import {AppComponent} from './components/app.component';
import {RequestsService} from './service/requests.service';
import {LoginComponent} from './components/login.component';
import {AdminUtilService} from './service/admin-util.service';
import {PrimengModule} from './shared/primeng/primeng.module';
import {ContentComponent} from './components/content.component';
import {SharedService} from './service/shared.service';
import {LoaderService} from './service/loader.service';
import {DateTimeService} from './service/date.time.service';
import {AppHeaderComponent} from './components/app.header.component';
import {AppFooterComponent} from './components/app.footer.component';
import {AppMenuComponent} from './components/app.menu.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {UserUpdateService} from './service/user.update.service';
import {InterceptorService} from './service/interceptor.service';
import {NgxUiLoaderConfig, NgxUiLoaderHttpModule, NgxUiLoaderModule, PB_DIRECTION, POSITION, SPINNER} from 'ngx-ui-loader';
import {ResourceNotFoundComponent} from './components/error/resource.not.found.component';
import {ChangePasswordComponent} from './components/change-password/change-password.component';
import {DialogModule} from 'primeng';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireMessagingModule} from '@angular/fire/messaging';
import {AngularFireModule} from '@angular/fire';
import {environment} from '../environments/environment';
import {MessagingService} from './service/messaging.service';
import {ToastrModule} from 'ngx-toastr';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {UserMenuComponent} from './components/help-support-menu/user-menu/user-menu.component';
import {ComnQuesMenuComponent} from './components/help-support-menu/comn-ques-menu/comn-ques-menu.component';
import {TechSupMenuComponent} from './components/help-support-menu/tech-sup-menu/tech-sup-menu.component';
import {ActivateSpComponent} from './components/activate-sp/activate-sp.component';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
    bgsColor: 'red',
    bgsPosition: POSITION.centerCenter,
    bgsSize: 40,
    bgsType: SPINNER.rectangleBounce, // background spinner type
    fgsType: SPINNER.chasingDots, // foreground spinner type
    pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
    pbThickness: 5, // progress bar thickness
    blur: 5,
    hasProgressBar: false,
    fastFadeOut: true
};

const routesNotShowLoader = [
    '/api/admin/autoCompleteUsers',
    '/api/marketing/autoCompleteEmailTemplate',
    '/api/marketing/autoCompleteSmsTemplate',
    '/api/marketing/autoCompleteNotification',
];

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        PrimengModule,
        DialogModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            defaultLanguage: 'en'
        }),
        NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
        NgxUiLoaderHttpModule.forRoot({
            excludeRegexp: routesNotShowLoader,
            showForeground: true
        }),
        // NgxUiLoaderHttpModule.forRoot({showForeground: true}),
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(environment.firebase),
        ToastrModule.forRoot({
            timeOut: 10000,
        }),
        DragDropModule,
    ],
    declarations: [
        AppComponent,
        AppHeaderComponent,
        AppFooterComponent,
        LoginComponent,
        ChangePasswordComponent,
        ContentComponent,
        AppMenuComponent,
        UserMenuComponent,
        ComnQuesMenuComponent,
        TechSupMenuComponent,
        ResourceNotFoundComponent,
        ActivateSpComponent
    ],
    providers:
        [
            {provide: LocationStrategy, useClass: PathLocationStrategy},
            {provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true},
            RequestsService,
            AdminUtilService,
            SharedService,
            LoaderService,
            DateTimeService,
            UserUpdateService,
            MessagingService, AsyncPipe,
            ExportService
        ],
    bootstrap:
        [AppComponent]
})

export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
