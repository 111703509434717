import {AdminUtilService} from "../service/admin-util.service";
import {SharedService} from "../service/shared.service";
import {RequestsService} from "../service/requests.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Component, OnInit} from '@angular/core';
import {APIURLConstants} from "../util/api.url.constants";

@Component({
    selector: 'content-component',
    templateUrl: '../templates/content.template.html',
})
export class ContentComponent implements OnInit {

    hideSidebarMenu: boolean = false;
    activeMenu = '';
    body = document.getElementsByTagName('body')[0];

    constructor(private router: Router,
                private activeRouted: ActivatedRoute,
                private adminUtilService: AdminUtilService,
                public sharedService: SharedService,
                private requestsService: RequestsService) {
    }

    ngOnInit() {
        if (!window.localStorage.getItem(btoa('access_token'))) {
            this.router.navigate(['/login']);
        } else {

        }
        this.sharedService.getSideMenu().subscribe(value => {
            this.activeMenu = value;
        });
        // this.activeMenu = localStorage.getItem('sideMenu');
    }

    menuToggle() {
        this.hideSidebarMenu = !this.hideSidebarMenu;
        if (this.hideSidebarMenu == true) {
            this.body.classList.add('hide-menu');
        }
        if (this.hideSidebarMenu == false) {
            this.body.classList.remove('hide-menu');
        }
    }

    logout() {
        this.requestsService.postRequest(
            APIURLConstants.LOGOUT_API_URL,
            {})
            .subscribe(
                (response: Response) => {
                    if (response['responseCode'] === 'AUTH_SUC_03') {
                        this.adminUtilService.logout();
                    }
                },
                (error: any) => {
                    this.adminUtilService.tokenExpired(error.status);
                }
            );
    }

}
