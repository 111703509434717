import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {RequestsService} from '../../service/requests.service';
import {Router, ActivatedRoute} from '@angular/router';
import {SharedService} from '../../service/shared.service';
import {AdminUtilService} from '../../service/admin-util.service';
import {MessageService} from 'primeng';
import {TranslateService} from '@ngx-translate/core';
import {APIURLConstants} from '../../util/api.url.constants';
import {NgForm} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-activate-service-provider',
    templateUrl: '../../components/activate-sp/activate-sp.component.html',
    // styleUrls: ['./activate-service-provider.component.css']
})
export class ActivateSpComponent implements OnInit, OnDestroy {

    newPassword: string;
    confirmPassword: string;
    forgotPasswordModal: boolean;
    isTokenValid = false;
    token = '';
    email = '';
    mobileNumber = '';
    mobileNumberDisplay = '';
    userId = '';
    otp = '';
    step: number;

    constructor(private requestsService: RequestsService,
                private router: Router,
                public sharedService: SharedService,
                public adminUtilService: AdminUtilService,
                private elementRef: ElementRef,
                public messageService: MessageService,
                private translate: TranslateService,
                private toastService: ToastrService,
                private activatedRoute: ActivatedRoute) {
        translate.setDefaultLang(localStorage.getItem('selectedLanguage'));
    }

    ngOnInit() {
        this.step = 1;
        this.token = this.activatedRoute.snapshot.params.id;
        if (this.token !== '') {
            const tokenArr = this.token.split('_a', 2);

            if (tokenArr[1]) {
                this.userId = tokenArr[1];
                this.activateUserAccount();
            }

        }
        // this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#dbeaca';
    }

    activateUserAccount() {

        if (this.userId === '') {
            this.toastService.error('Invalid token', 'Error');
            return false;
        }
        this.requestsService.putUnAuthRequest(APIURLConstants.ACTIVATE_SERVICE_PROVIDER + this.userId , {status: 1}).subscribe(
            (response) => {
                if (response['responseStatus'] === 'SUCCESS') {

                    this.router.navigate(['/login']);

                } else {
                    this.toastService.error(response['responseMessage'], response['responseStatus']);
                }
            }
        );
    }

    validateUserOtp() {

        if (this.otp === '') {
            this.toastService.error('Otp is required', 'OTP');
            return false;
        }
        this.requestsService.postRequestUnAuth('/user/validateSpOtp' ,
            {email: this.email, mobile_number: this.mobileNumber, otp: this.otp}, '').subscribe(
            (response) => {
                if (response['responseCode'] === 'CUS_SUC_07') {
                    this.toastService.success(response['responseMessage'], response['responseStatus']);
                    this.step = 2;
                } else {
                    this.toastService.error(response['responseMessage'], response['responseStatus']);
                }
            },
            (error: any) => {
                this.toastService.error('Internal server error, please contact with administrator', 'Error');
            });
    }

    resetSpPassword() {

        if (this.newPassword === '' || this.confirmPassword === '') {
            this.toastService.error('Required fields are empty.', 'Error');
            return false;
        }
        if (this.newPassword.toString().length < 8) {
            this.toastService.error('Password must be 8 characters long.', 'Weak Password');
            return false;
        }
        if (this.newPassword !== this.confirmPassword) {
            this.toastService.error('Confirm password not matched with password.', 'Error');
            return false;
        }

        this.requestsService.postRequestUnAuth('/user/resetSpPassword' ,
            {id: this.userId, password: this.newPassword}, '').subscribe(
            (response) => {
                if (response['responseCode'] === 'AUTH_SUC_04') {
                    this.toastService.success(response['responseMessage'], response['responseStatus']);
                    this.router.navigate(['/login']);

                } else {
                    this.toastService.error(response['responseMessage'], response['responseStatus']);
                }
            },
            (error: any) => {
                this.toastService.error('Internal server error, please contact with administrator', 'Error');
            });
    }

    resendOtp() {

        if (this.mobileNumber === '' ) {
            this.toastService.error('Mobile Number not found, Token Expired.', 'Error');
            return false;
        }

        this.requestsService.postRequestUnAuth('/customer/resendOTPViaSMS' ,
            {mobile_no: this.mobileNumber}, '').subscribe(
            (response) => {
                if (response['responseCode'] === 'CUS_SUC_05') {
                    this.toastService.success(response['responseMessage'], response['responseStatus']);

                } else {
                    this.toastService.error(response['responseMessage'], response['responseStatus']);
                }
            },
            (error: any) => {
                this.toastService.error('Internal server error, please contact with administrator', 'Error');
            });
    }

    ngOnDestroy() {
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#fff';
    }

    confirm(form: NgForm) {
        if (form.valid) {
            if (this.isTokenValid) {
                this.requestsService.postRequest(
                    APIURLConstants.RESET_PASSWORD_API_URL
                    , {
                        email: this.email,
                        password: this.newPassword,
                        password_confirmation: this.confirmPassword,
                        token: this.token
                    })
                    .subscribe(
                        (response: Response) => {
                            if (response['responseStatus'] === 'SUCCESS') {
                                this.router.navigate(['/login']);
                            }
                        });
            }
        } else {
            this.messageService.add({
                key: 'loginToast',
                severity: 'error',
                summary: 'Change Password',
                detail: 'Fields are required.'
            });
        }
    }

    cancel() {
        this.router.navigate(['./login']);
    }


}
