import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {RequestsService} from '../service/requests.service';
import {AdminUtilService} from '../service/admin-util.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {APIURLConstants} from '../util/api.url.constants';
import {PlatformLocation} from '@angular/common';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../service/shared.service';
import {MessagingService} from '../service/messaging.service';
import {environment} from '../../environments/environment';
import {CountryCode} from '../util/country-code';

@Component({
    selector: 'login-component',
    templateUrl: '../templates/login.template.html',
    styleUrls: ['../style/login.css']
})
export class LoginComponent implements OnInit, OnDestroy {

    countryCode = CountryCode.countryCode;
    username: string;
    password: string;
    rememberMe = false;
    forgotPasswordModal: boolean;
    fcmToken: any;
    loginForm: FormGroup;
    registerServiceProviderForm: FormGroup;
    passFieldType: boolean;
    updatePhoneNumber = false;
    formType = 'login';
    // formType = 'verify_otp';
    spPassFieldType: boolean;
    spConfirmPassFieldType: boolean;
    isPasswordMatched: boolean = true;
    otp = '';
    spRegistraionEmail: any;
    spRegistraionMobileNumber: any;
    selectedLanguage = 'English';
    showMobileNumber: string;
    createSpRequestData: any;
    tempUser: any;
    counter = 90;
    showActivateAccModal: boolean = false;
    resentEmailData: any;

    constructor(private requestsService: RequestsService,
                private router: Router,
                public adminUtilService: AdminUtilService,
                private elementRef: ElementRef,
                private translate: TranslateService,
                private plateFormLocation: PlatformLocation,
                private toastrService: ToastrService,
                public sharedService: SharedService,
                private formBuilder: FormBuilder,
                private messagingService: MessagingService) {
        translate.setDefaultLang(localStorage.getItem('selectedLanguage'));
    }

    ngOnInit() {
        const lang = localStorage.getItem('selectedLanguage');
        this.selectedLanguage = lang === 'ar' ? 'Arabic' : 'English';

        if (window.localStorage.getItem(btoa('access_token'))) {
            this.router.navigate(['dashboard']);
        } else {
            this.router.navigate(['/login']);

            this.messagingService.requestPermission();
            this.sharedService.fcmToken.subscribe((_token) => {
                this.fcmToken = _token;
            });
        }
        this._formBuilder();
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#dbeaca';
        this.setRememberedCredentials();
    }

    _formBuilder() {
        this.loginForm = this.formBuilder.group({
            user_name: ['', Validators.required],
            password: ['', Validators.required],
            remember_me: [null],
        });
        this.registerServiceProviderForm = this.formBuilder.group({
            enFirstName: [null, Validators.compose([Validators.required])],
            enLastName: [null, Validators.compose([Validators.required])],
            email: [null, Validators.compose([Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')])],
            password: [null, Validators.compose([Validators.required])],
            confirmPassword: [null, Validators.compose([Validators.required])],
            mobileNumber: [null, Validators.compose([Validators.required])],
            countryCode: [null, Validators.compose([Validators.required])],
        });
    }

    ngOnDestroy() {
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#fff';
    }

    setRememberedCredentials() {
        if (JSON.parse(localStorage.getItem('credentials')) !== null) {
            const credentials = JSON.parse(localStorage.getItem('credentials'));
            if (credentials) {
                this.loginForm.patchValue({
                    user_name: credentials.user_name,
                    password: credentials.password,
                    remember_me: credentials.remember_me
                });
            }
        }
    }
    togglePassField() {
        this.passFieldType = !this.passFieldType;
    }

    onClickResetPassword(email) {
        if (email && email !== '') {
            this.forgotPasswordModal = false;
            const url = this.plateFormLocation['location'].origin + '/change-password';
            this.requestsService.postRequest(APIURLConstants.FORGOT_PASSWORD_API_URL, {email, url}).subscribe(
                (response: Response) => {
                    if (response['responseCode'] && response['responseCode'] === 'VALID_ERR_01') {
                        this.toastrService.error(response['responseMessage'], 'Reset Password Link');
                    } else {
                        this.toastrService.info(response['message'], 'Reset Password Link');
                    }
                }
            );
        }
    }

    login() {
        if (this.loginForm.invalid) {
            this.toastrService.error('Fields are required.', 'Sign In');
            return;
        }
        this.requestsService.postRequestAccessToken(
            APIURLConstants.LOGIN_API_URL
            , {
                user_name: this.loginForm.value.user_name,
                password: this.loginForm.value.password,
                fcm_token: this.fcmToken,
                user_type: 'service_providers',
                device_type: 'web'
            })
            .subscribe(
                (response: Response) => {
                    if (response['responseCode'] === 'AUTH_SUC_01') {
                        this.isRememberMe(this.loginForm.value);
                        const obj = JSON.parse(JSON.stringify(response['responseData']));
                        localStorage.setItem(btoa('sp_user_branches'), JSON.stringify(response['responseData']['sp_user_branches']));
                        localStorage.setItem(btoa('access_token'), response['token']);
                        localStorage.setItem(btoa('user-info'), JSON.stringify(obj));
                        this.adminUtilService.userInfo = JSON.parse(JSON.stringify(response['responseData']));
                        this.router.navigate(['/dashboard']);
                    } else {
                        this.toastrService.error(response['responseMessage'], 'Sign In');
                        if(response['responseCode'] === 'AUTH_ERR_02'){
                            this.resentEmailData = response['responseData']
                            this.showActivateAccModal = true
                        }
                    }
                }, (error: any) => {
                    this.adminUtilService.tokenExpired(error.status);
                });
    }

    changeLanguage(language: string) {
        this.translate.use(language);
        localStorage.setItem('selectedLanguage', language);
        window.location.reload();
    }

    forgotPassword() {
        this.forgotPasswordModal = true;
    }
    resendActivationLink(){
        this.requestsService.postRequestAccessToken(
            APIURLConstants.RESEND_ACTIVATION_MAIL_API_URL
            , {
                user_id: this.resentEmailData?.id,
                email: this.resentEmailData.service_providers?.email,
                mobile_number: this.resentEmailData?.mobile_number,
                sp_id: this.resentEmailData?.service_providers?.id,
            })
            .subscribe(
                (response: Response) => {
                    if (response['responseCode'] === 'USR_SUC_01') {
                        this.toastrService.success(response['responseMessage'], response['responseStatus']);
                        this.showActivateAccModal = false
                    } else {
                        this.toastrService.error(response['responseMessage'], response['responseStatus']);
                    }
                }, (error: any) => {
                    this.adminUtilService.tokenExpired(error.status);
                });
    }
    isRememberMe(values) {
        if (values.remember_me) {
            localStorage.setItem('credentials', JSON.stringify(values));
        } else {
            localStorage.removeItem('credentials');
        }
    }

    getAddClinicUrl() {
        const url = environment.http_protocol + environment.http_separator +  environment.website_end_point_url + '/add-clinic';
        (window as any).open(url, "_blank");

    }

    spTogglePassField() {
        this.spPassFieldType = !this.spPassFieldType;
    }

    toggleConfirmPassField() {
        this.spConfirmPassFieldType = !this.spConfirmPassFieldType;
    }

    passwordError(data) {
        if (String(data.password) !== String(data.confirmPassword)) {
            this.isPasswordMatched = false;
            return;
        } else {
            this.isPasswordMatched = true;
        }
    }

    buildSpRequest(data: any) {
        const sp: any = {};
        sp.en_name = data.enFirstName;
        sp.en_last_name = data.enLastName;
        sp.ar_name = '';
        sp.password = data.password;
        sp.password_confirmation = data.confirmPassword;
        sp.email = data.email;
        sp.mobile_number = data.countryCode + '-' + data.mobileNumber;
        sp.user_type = 'service_providers';
        sp.is_active = 0;
        sp.send_activation_email = 1;
        sp.url = this.plateFormLocation['location'].origin + '/activate-sp';

        this.spRegistraionEmail = sp.email;
        this.spRegistraionMobileNumber = sp.mobile_number;

        return sp;
    }


    saveServiceProvider(data: any) {
        if (this.registerServiceProviderForm.invalid) {
            this.toastrService.error('Required fields are missing...', 'Error');
            return;
        }
        const createSpRequest = this.buildSpRequest(data);
        this.createSpRequestData = createSpRequest;
        this.requestsService.postRequest(
            APIURLConstants.CREATE_USER_API_URL,
            createSpRequest)
            .subscribe(
                (response: Response) => {
                    if (response['responseCode'] === 'AUTH_SUC_07') {
                        this.toastrService.success(response['responseMessage'], response['responseStatus']);
                        this.formType = 'verify_otp';
                        this.counter=90
                        this.setCounter()
                        this.showMobileNumber = createSpRequest?.mobile_number;
                        this.tempUser = response['responseData'];
                    } else {
                        this.toastrService.error(response['responseMessage'], response['responseStatus']);
                    }
                    // this.loaderService.inProgress = false;
                },
                (error: any) => {
                    this.toastrService.error('Internal server error, please contact with administrator', 'Error');
                    this.adminUtilService.tokenExpired(error.status);
                });
    }

    updateServiceProviderNumber(data: any) {
        if (this.registerServiceProviderForm.invalid) {
            this.toastrService.error('Required fields are missing...', 'Error');
            return;
        }

        if (this.tempUser && this.tempUser?.sp_id) {


            const createSpRequest = this.buildSpRequest(data);

            this.requestsService.postRequestAccessToken
            (APIURLConstants.RESET_SP_NUMBER_API_URL + '/' + this.tempUser?.sp_id, {mobile_number: createSpRequest?.mobile_number}).subscribe(
                (response) => {
                    if (response['responseCode'] === 'ADMIN_SUC_02') {
                        this.toastrService.success(response['responseMessage'], response['responseStatus']);

                        this.updatePhoneNumber = true;
                        this.formType = 'verify_otp';
                        this.counter=90
                        this.setCounter()

                        this.showMobileNumber = createSpRequest?.mobile_number;
                    }
                }, (response) => {
                    this.toastrService.error(response?.error?.message, 'Error');
                }
            );
        } else {
            this.toastrService.error('Unable to update.', 'Error');
            return;
        }
    }
    setCounter(){
        let myInterval = setInterval(()=> {
            this.counter--
            if(this.counter==0){
                clearInterval(myInterval)
            }
        }, 1000);
    }
    public setSignUpData() {
        this.formType = 'signup';
        this.updatePhoneNumber = true;
        const createUserRequest = this.createSpRequestData;

        if (this.createSpRequestData) {
            this.registerServiceProviderForm.patchValue({
                enFirstName: createUserRequest?.en_name,
                enLastName: createUserRequest?.en_last_name,
                email: createUserRequest?.email,
                password: createUserRequest?.password,
                confirmPassword: createUserRequest?.password_confirmation,
                countryCode: createUserRequest?.mobile_code,
                mobileNumber: createUserRequest?.mobile_number,
            });
        }

    }
    onCancel() {
        this.formType = 'login';
        this.registerServiceProviderForm.reset();
    }

    validateUserOtp() {

        if (this.otp === '') {
            this.toastrService.error('Otp is required', 'OTP');
            return false;
        }
        if (this.spRegistraionEmail && this.spRegistraionMobileNumber) {

            this.requestsService.postRequestUnAuth('/user/validateSpOtp',
                {
                    email: this.spRegistraionEmail,
                    mobile_number: this.spRegistraionMobileNumber,
                    otp: this.otp
                }, '').subscribe(
                (response) => {
                    if (response['responseCode'] === 'CUS_SUC_07') {
                        this.toastrService.success(response['responseMessage'], response['responseStatus']);
                        this.formType = 'sp_reg_success';
                        this.updatePhoneNumber = false;

                    } else {
                        this.toastrService.error(response['responseMessage'], response['responseStatus']);
                    }
                },
                (error: any) => {
                    this.toastrService.error('Internal server error, please contact with administrator', 'Error');
                });
        }
    }

    resendOTPSms() {
        if (this.showMobileNumber) {
            this.requestsService.postRequestAccessToken(APIURLConstants.RESEND_OTP_PHONE_NUMBER_API_URL
                , {mobile_no: this.showMobileNumber})
                .subscribe(
                    (response: Response) => {
                        if (response['responseCode'] === 'CUS_SUC_05') {
                            this.otp = response['responseData'].otp;
                            this.toastrService.success(response['responseMessage'], 'Resend OTP');
                        } else {
                            this.toastrService.error(response['responseMessage'], 'Resend OTP');
                        }
                        this.counter=90
                        this.setCounter()

                    },
                    (error: any) => {
                        this.toastrService.error('Internal Server Error. Please contact administrator.',
                            'Resend OTP');
                    });
        } else {
            this.toastrService.error('Invalid Phone Number.', 'Resend OTP');
        }
    }

/*
    updateServiceProvider(data: any) {
        const formData: FormData = new FormData();

        formData.append('mobile_number', data.countryCode + '-' + data.mobileNumber);


        this.requestsService.postRequestWithMultipartFormData
        (APIURLConstants.UPDATE_SERVICE_PROVIDER + '/' + this.myAccount.id, formData).subscribe(
            (response) => {
                if (response['responseCode'] === 'ADMIN_SUC_02') {
                    this.myAccount = response['responseData'];
                    localStorage.setItem(btoa('user-info'), JSON.stringify(response['responseData']));
                    // this.adminUtilService.userInfo = JSON.parse(JSON.stringify(response['responseData']));
                    // this.adminUtilService.userHasPermission(1);
                    this.toastService.success(response['responseMessage'], response['responseStatus']);

                    this.phoneNumberIsReadOnly = true;
                    this.getUserDetail();
                    this.onCancelButtonClicked();
                } else {
                    if (response['errors']['mobile_number']) {
                        this.toastService.error(response['errors']['mobile_number'], response['errors']);
                    } else {
                        this.toastService.error(response['responseMessage'], response['responseStatus']);
                    }
                }
            }, (response) => {
                this.toastService.error(response?.error?.message, 'Error');
                // this.toastService.error('Mobile number already exist! Please try anyother number', 'Error');
            }
        );
    }
*/


}
