import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'content-footer',
    templateUrl: '../templates/app.footer.template.html'
})
export class AppFooterComponent {

    constructor(private translate: TranslateService) {
        translate.setDefaultLang(localStorage.getItem('selectedLanguage'));
    }
}
