import {Component, OnInit} from '@angular/core';
import {APIURLConstants} from '../../../util/api.url.constants';
import {RequestsService} from '../../../service/requests.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SharedService} from '../../../service/shared.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.css']
})
export class UserMenuComponent implements OnInit {

    sections = [];
    activeSection: any;

    constructor(private requestsService: RequestsService, private router: Router,
                public sharedService: SharedService,
                private translate: TranslateService,
                private activatedRoute: ActivatedRoute) {
        translate.setDefaultLang(localStorage.getItem('selectedLanguage'));
    }

    ngOnInit(): void {
        this.getSections();
    }

    getSections() {
        this.requestsService.getRequest(
            APIURLConstants.GET_SECTION_API_URL)
            .subscribe(
                (response: Response) => {
                    if (response['responseCode'] === 'SEC_SUC_01') {
                        this.sections = response['responseData'];
                        this.onClickSection(this.sections[0].id);
                    }
                });
    }

    onClickSection(id) {
        this.activeSection = id;
        this.router.navigate([`support/user-manual/${id}`]).then(
            () => {
                this.sharedService.userManual.next(id);
            }
        );
    }

}
