import {Component} from '@angular/core';
import {RequestsService} from '../../service/requests.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'resource-notfound-component',
    templateUrl: '../../templates/errors/resource.not.found.template.html'
})
export class ResourceNotFoundComponent {

    constructor(private requestsService: RequestsService,
                private router: Router,
                private translate: TranslateService) {
        translate.setDefaultLang(localStorage.getItem('selectedLanguage'));
    }

    ngOnInit() {

    }

}
