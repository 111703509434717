import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {LoaderService} from './loader.service';

@Injectable()
export class RequestsService {

    constructor(private http: HttpClient,
                public loaderService: LoaderService) {
    };

    getToken() {
        return localStorage.getItem(btoa('access_token'));
    }

    getBEAPIServer() {
        let protocol = environment.http_protocol;
        let server = environment.api_end_point_url;
        let port = environment.api_end_point_port;
        let contextPath = '/' + environment.api_context_path;
        if (protocol === '' || !protocol || server === '' || !server) {
            return '';
        } else {
            if (port === '' || !port) {
                return protocol + environment.http_separator + server + ':' + port + contextPath;
            } else {
                return protocol + environment.http_separator + server + ':' + port + contextPath;
            }
        }
    }

    postRequestAccessToken(url: any, _params: any) {
        const reqHeader = new HttpHeaders(
            {}
        );
        let URI = this.getBEAPIServer() + url;
        return this.http.post(URI, _params, {headers: reqHeader});
    }

    getRequest(url: any) {

        const reqHeader = new HttpHeaders(
            {
                'Authorization': 'Bearer ' + this.getToken(),
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        );
        return this.http.get(this.getBEAPIServer() + url, {headers: reqHeader});
    }

    getRequestUnAuth(url: any) {
        const reqHeader = new HttpHeaders(
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        );
        return this.http.get(this.getBEAPIServer() + url, {headers: reqHeader});
    }

    postRequest(url: any, _params: any) {
        const reqHeader = new HttpHeaders(
            {
                'Authorization': 'Bearer ' + this.getToken(),
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        );
        return this.http.post(this.getBEAPIServer() + url, _params, {headers: reqHeader});
    }

    postRequestUnAuth(url: any, _params: any, tenantId: string) {
        const reqHeader = new HttpHeaders(
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        );
        return this.http.post(this.getBEAPIServer() + url, _params, {headers: reqHeader});
    }

    postRequestWithMultipartFormData(url: any, data: any) {
        const reqHeader = new HttpHeaders(
            {
                'Authorization': 'Bearer ' + this.getToken(),
                'Accept': 'application/json',
            }
        );
        return this.http.post(this.getBEAPIServer() + url, data, {headers: reqHeader});
    }

    deleteRequest(url: any) {
        const reqHeader = new HttpHeaders(
            {
                'Authorization': 'Bearer ' + this.getToken(),
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        );
        return this.http.delete(this.getBEAPIServer() + url, {headers: reqHeader});
    }

    putRequest(url: any, _params?: any) {
        const reqHeader = new HttpHeaders(
            {
                'Authorization': 'Bearer ' + this.getToken(),
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        );
        return this.http.put(this.getBEAPIServer() + url, _params, {headers: reqHeader});
    }

    putRequestWithMultipartFormData(url: any, data: any) {
        const reqHeader = new HttpHeaders(
            {
                'Authorization': 'Bearer ' + this.getToken(),
                'Accept': 'application/json',
            }
        );
        return this.http.put(this.getBEAPIServer() + url, data, {headers: reqHeader});
    }

    putUnAuthRequest(url: any, _params: any) {
        const reqHeader = new HttpHeaders(
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        );
        return this.http.put(this.getBEAPIServer() + url, _params, {headers: reqHeader});
    }

    patchRequest(url: any, _params?: any) {
        const reqHeader = new HttpHeaders(
            {
                'Authorization': 'Bearer ' + this.getToken(),
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        );
        return this.http.patch(this.getBEAPIServer() + url, _params, {headers: reqHeader});
    }

    getRequestFile(url: any) {
        const reqHeader = new HttpHeaders(
            {
                'Authorization': 'Bearer ' + this.getToken(),
            }
        );
        return this.http.get(this.getBEAPIServer() + url, {headers: reqHeader, responseType: 'arraybuffer'});
    }

}
