import {Injectable} from '@angular/core';
import {AdminUtilService} from "./admin-util.service";

@Injectable()
export class DateTimeService {

    monthNames: string[] = ['January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    shortMonthNames: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    constructor(public adminUtilService: AdminUtilService) {
    }

    public getMonthNumberByName(name: string): number {
        return this.monthNames.indexOf(name) + 1;
    }

    public getMonthNameByNumber(num: number): string {
        return this.shortMonthNames[num];
    }

}
