import {Component, OnInit} from '@angular/core';
import {ContentComponent} from './content.component';
import {Router} from '@angular/router';
import {UserUpdateService} from '../service/user.update.service';
import {RequestsService} from '../service/requests.service';
import {AdminUtilService} from '../service/admin-util.service';
import {SharedService} from '../service/shared.service';
import {APIURLConstants} from '../util/api.url.constants';
import {Dialog, MessageService} from 'primeng';
import {PlatformLocation} from '@angular/common';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'content-header',
    templateUrl: '../templates/app.header.template.html'
})
export class AppHeaderComponent implements OnInit {

    changePasswordModal: boolean;
    confirmChangePasswordModal: boolean;
    mobileVerificationModal: boolean;
    changePasswordMsg: boolean;
    changeNewPasswordModal: boolean;
    logoutModal: boolean;
    emailVerificationModal: boolean;
    sendReSetPasswordMsg: boolean;
    dialog: Dialog;
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
    userInfo = {};
    otpCode: '';
    resendButton = false;
    TimerValue = 90;
    counter = 0;
    passFieldType1: boolean;
    passFieldType2: boolean;
    passFieldType3: boolean;
    allNotificationsCount: any;
    allNotifications: any = [];

    constructor(public app: ContentComponent,
                private router: Router,
                private translate: TranslateService,
                private userUpdateService: UserUpdateService,
                private requestsService: RequestsService,
                public adminUtilService: AdminUtilService,
                public sharedService: SharedService,
                private messageService: MessageService,
                private toastrService: ToastrService,
                private plateFormLocation: PlatformLocation) {
        translate.setDefaultLang(localStorage.getItem('selectedLanguage'));
    }

    ngOnInit() {
        if (window.localStorage.getItem(btoa('access_token'))) {
            this.requestsService.postRequest(
                APIURLConstants.LOGGED_IN_USER_URL,
                {'user_type': 'service_providers'})
                .subscribe(
                    (response: Response) => {
                        if (response['responseCode'] === 'USR_SUC_01') {
                            if (localStorage.getItem(btoa('user-info'))) {
                                const obj = JSON.parse(JSON.stringify(response['responseData']));
                                this.userInfo = obj;
                                localStorage.setItem(btoa('user-info'), JSON.stringify(obj));
                                this.adminUtilService.userInfo = obj;
                                this.sharedService.isUserLoggedIn = true;
                                this.sharedService.userLoggedIn.next(true);
                                this.userUpdateService.newSubject.subscribe(
                                    profileImgUrl => this.adminUtilService.userInfo.profileImg = profileImgUrl
                                );
                                this.getAllNotification()
                                this.getAllNotificationCount()
                            } else {
                                this.adminUtilService.logout();
                            }
                        }
                    },
                    (error: any) => {
                        this.adminUtilService.tokenExpired(error.status);
                    }
                );
            // when API is in process - synchronizing
            this.adminUtilService.userInfo = JSON.parse(localStorage.getItem(btoa('user-info')));
        }

        // event trigger when user is disabled
        this.sharedService.forcefullyLogoutDisabledUser().subscribe(x => {
            this.app.logout();
        });

        // event trigger when user receive any notification
        this.sharedService.processUserNotifications().subscribe(x => {
            // process user notifications here
            console.log(x)
            this.allNotificationsCount=this.allNotificationsCount+1
        });

        // event trigger when user press the browser back button
        this.sharedService.onBrowserBackButtonPress().subscribe(x => {
            const currentState = JSON.parse(localStorage.getItem('currentState'));
            if (!currentState?.header || currentState?.header === '') {
                setTimeout(() => {
                    this.router.navigate(['/dashboard']);
                }, 300);
            }
            // window.location.pathname
        });
    }
    getAllNotification() {
        this.requestsService.getRequest(APIURLConstants.GET_ALL_NOTIFICATIONS + this.userInfo["id"]).subscribe(
            (response: Response) => {
                if (response['responseCode'] === 'NOTIFY_SUC_01') {
                    this.allNotifications = response['responseData']
                }
            }
        );
    }
    getAllNotificationCount() {
        this.requestsService.getRequest(APIURLConstants.GET_ALL_NOTIFICATIONS_COUNT + this.userInfo["id"]).subscribe(
            (response: Response) => {
                if (response['responseCode'] === 'NOTIFY_SUC_01') {
                    this.allNotificationsCount = response['responseData']
                }
            }
        );
    }
    clearAllNotificationCount() {
        if(this.allNotificationsCount === 0) return
        this.requestsService.putRequest(APIURLConstants.CLEAR_ALL_NOTIFICATIONS + this.userInfo["id"]).subscribe(
            (response: Response) => {
                if (response['responseCode'] === 'NOTIFY_SUC_02') {
                    // this.allNotifications = []
                    this.allNotificationsCount = 0
                }
            }
        );
    }
    showAllNotifications() {
        this.router.navigate(['/my-account'],
            {queryParams: {activeTab: 'activity-log'}}
        );
    }
    openNotificationSettings() {
        this.sharedService.setNotificationTabStatus(true);
        this.router.navigate(['/my-account/']);
    }

    waitForDialog() {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(true);
            }, 1000);
        });
    }

    onClickLogout() {
        this.logoutModal = false;
        this.waitForDialog().then((result) => {
            if (result === true) {
                this.app.logout();
            }
        });
    }

    onClickConfirm(currentPass, newPass, confirmPass) {
        this.changePasswordModal = false;
        const params = {
            old_password: currentPass,
            password: newPass,
            password_confirmation: confirmPass
        };
        this.requestsService.putRequest(APIURLConstants.CHANGE_PASSWORD_API_URL + '/' + this.userInfo['id'], params).subscribe(
            (response: Response) => {
                if (response['responseCode'] === 'AUTH_SUC_04') {
                    this.changePasswordMsg = true;
                }
                else
                {
                    this.toastrService.error(response['responseMessage'], response['responseStatus']);
                }

            }
        );
    }

    sendOtpCode() {
        this.counter = this.TimerValue;
        this.resendButton = false;
        const param = {
            mobile_number: this.userInfo['mobile_number']
        };
        return this.requestsService.postRequest(APIURLConstants.RESET_PASSWORD_OTP_API_URL, param);
    }

    async onClickConfirmSendOpt() {
        await this.sendOtpCode().subscribe(
            (response: Response) => {
                if (response['responseCode'] === 'SMS_SUC_01') {
                    this.otpCode = response['responseData'].code;
                    this.mobileVerificationModal = true;
                    this.confirmChangePasswordModal = false;
                    this.startCountdown();
                } else if (response['responseCode'] === 'SMS_ERR_01') {
                    this.toastrService.error(response['responseMessage'], response['responseStatus']);
                }
            }
        );
    }

    startCountdown() {
        const interval = setInterval(() => {
            this.counter--;
            if (this.counter < 1) {
                clearInterval(interval);
                this.resendButton = true;
            }
        }, 1000);
    }

    onClickResend() {
        if (this.counter < 1) {
            this.sendOtpCode().subscribe(
                (response: Response) => {
                    if (response['responseCode'] === 'SMS_SUC_01') {
                        this.otpCode = response['responseData'].code;
                        this.startCountdown();
                    } else if (response['responseCode'] === 'SMS_ERR_01') {
                        this.toastrService.error(response['responseMessage'], response['responseStatus']);
                    }
                }
            );
        }
    }

    onConfirmVerificationCode(verificationCode) {
        if (verificationCode == this.otpCode) {
            this.changeNewPasswordModal = true;
            this.mobileVerificationModal = false;
        } else {
            this.messageService.add({
                key: 'loginToast',
                severity: 'error',
                summary: 'Error',
                detail: 'OTP Code not match'
            });
        }
    }

    newPasswordChange(newPass, confirmPass) {
        const params = {
            password: newPass,
            password_confirmation: confirmPass
        };
        this.requestsService.putRequest(APIURLConstants.CHANGE_PASSWORD_WITH_OTP_API_URL + '/' + this.userInfo['id'], params).subscribe(
            (response: Response) => {
                if (response['responseCode'] === 'AUTH_SUC_04') {
                    this.changePasswordMsg = true;
                    this.changeNewPasswordModal = false;
                }
            }
        );
    }

    onClickResetPasswordWitEmail(email) {
        if (email && email !== '') {
            const url = this.plateFormLocation['location'].origin + '/change-password';
            this.requestsService.postRequest(APIURLConstants.FORGOT_PASSWORD_API_URL, {email, url}).subscribe(
                (response: Response) => {
                    this.emailVerificationModal = false;
                    this.sendReSetPasswordMsg = true;
                    this.messageService.add({
                        key: 'loginToast',
                        severity: 'info',
                        summary: 'Reset Password Link',
                        detail: response['message']
                    });
                }
            );
        }
    }

    changeLanguage(language: string) {
        this.adminUtilService.changeLayoutOrientation(language, 'header');
        this.translate.use(language);
        localStorage.setItem('selectedLanguage', language);
        window.location.reload();
    }

    togglePassField(v) {
        if (v === 1) {
            this.passFieldType1 = !this.passFieldType1;
        }
        else if (v === 2) {
            this.passFieldType2 = !this.passFieldType2;
        }
        else if (v === 3) {
            this.passFieldType3 = !this.passFieldType3;
        }
    }
}
