import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SharedService} from '../../../service/shared.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-tech-sup-menu',
    templateUrl: './tech-sup-menu.component.html',
    styleUrls: ['./tech-sup-menu.component.css']
})
export class TechSupMenuComponent implements OnInit {

    activeLink = '';

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private translate: TranslateService,
                public sharedService: SharedService) {
        translate.setDefaultLang(localStorage.getItem('selectedLanguage'));
    }

    ngOnInit(): void {
        this.activeLink = this.sharedService.getUrlSegment()[2]?.path || '';

    }

    onClickLink(path) {
        this.activeLink = path;
        this.router.navigate([`tech-support/${path}`], {relativeTo: this.activatedRoute});
    }

}
