// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
    production: false,
    http_protocol: 'https',
    http_separator: '://',
    website_end_point_url: 'medicta.info-tera.com',
    api_end_point_url: 'asis-api.medicta.com',
    api_end_point_port: '',
    api_context_path: 'api',
    firebase: {
        apiKey: 'AIzaSyBR3fc3gkn2ONu5gEMux-iM7oCGo9f_3cU',
        authDomain: 'medicta-dce27.firebaseapp.com',
        databaseURL: 'https://medicta-dce27.firebaseio.com',
        projectId: 'medicta-dce27',
        storageBucket: 'medicta-dce27.appspot.com',
        messagingSenderId: '443058046595',
        appId: '1:443058046595:web:09754907b43ed984493db6',
        measurementId: 'G-ZJFJGX6MYX'
    }
};
