import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MessagingService} from '../service/messaging.service';
import {SharedService} from '../service/shared.service';
import {MessageService} from 'primeng';
import {ToastrService} from 'ngx-toastr';
import {AdminUtilService} from '../service/admin-util.service';
import { HostListener } from '@angular/core';

@Component({
    selector: 'app-root',
    templateUrl: '../templates/app.template.html',
    styleUrls: ['./app.components.css']

})
export class AppComponent implements OnInit, OnDestroy {

    message;

    constructor(private translate: TranslateService,
                private messagingService: MessagingService,
                private elementRef: ElementRef,
                private messageService: MessageService,
                public adminUtilService: AdminUtilService,
                public sharedService: SharedService,
                private toastrService: ToastrService) {
        translate.setDefaultLang(localStorage.getItem('selectedLanguage'));
    }

    ngOnInit() {
        //  this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#dbeaca';
        this.messagingService.requestPermission();
        this.messagingService.receiveMessage();
        this.messagingService.currentMessage.subscribe((message) => {
            // console.log(message)
            if (message) {
                // this.toastrService.success(message.notification.body, message.notification.title);

                if (message.notification.body === 'userDisabled') {

                    this.sharedService.userDisabled.next();

                } else {
                    this.sharedService.userNotifications.next();
                }
            }
        });
        if (!localStorage.getItem('selectedLanguage')) {
            localStorage.setItem('selectedLanguage', 'en');
        }
        let language = localStorage.getItem('selectedLanguage');
        this.adminUtilService.changeLayoutOrientation(language, 'header');
        this.translate.use(language);
        this.adminUtilService.selectedLanguage = language;
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event) {
        this.sharedService.browserBackButtonPressed.next();
    }

    ngOnDestroy() {
        // this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#fff';
    }
}
