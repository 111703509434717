import {RouterModule, Routes} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';
import {LoginComponent} from './components/login.component';
import {ContentComponent} from './components/content.component';
import {ResourceNotFoundComponent} from './components/error/resource.not.found.component';
import {ChangePasswordComponent} from './components/change-password/change-password.component';
import {ActivateSpComponent} from './components/activate-sp/activate-sp.component';
import { AuthGuard } from './auth.guard';

export const routes: Routes = [
    {path: '', redirectTo: 'login', pathMatch: 'full'},
    {path: 'login', component: LoginComponent},
    {path: 'change-password/:id', component: ChangePasswordComponent},
    {path: 'not-found', component: ResourceNotFoundComponent},
    {
        path: 'dashboard', component: ContentComponent,
        children: [
            {path: '', loadChildren: './components/dashboard/dashboard.module#DashboardModule'},
        ],
    },
    {
        path: 'branch', component: ContentComponent,
        children: [
            {path: '', loadChildren: './components/branch/branch-details.module#BranchDetailsModule'},
        ],
        canActivate: [AuthGuard],
        data: {permissionId: [5]}
    },
    {
        path: 'medical-service', component: ContentComponent,
        children: [
            {path: '', loadChildren: './components/medical-service/medical-services.module#MedicalServicesModule'},
        ],
        canActivate: [AuthGuard],
        data: {permissionId: [6]}
    },
    {
        path: 'doctor', component: ContentComponent,
        children: [
            {path: '', loadChildren: './components/doctor/doctors.module#DoctorsModule'},
        ],
        canActivate: [AuthGuard],
        data: {permissionId: [8]}
    },
    {
        path: 'schedule', component: ContentComponent,
        children: [
            {path: '', loadChildren: './components/appointment/appointment.module#AppointmentModule'},
        ],
        canActivate: [AuthGuard],
        data: {permissionId: [10]}
    },
    {
        path: 'user', component: ContentComponent,
        children: [
            {path: '', loadChildren: './components/user/users.module#UsersModule'},
        ],
        canActivate: [AuthGuard],
        data: {permissionId: [11]}
    },
    {
        path: 'offer', component: ContentComponent,
        children: [
            {path: '', loadChildren: './components/offer/offers.module#OffersModule'},
        ],
        canActivate: [AuthGuard],
        data: {permissionId: [13]}
    },
    {
        path: 'reservation', component: ContentComponent,
        children: [
            {path: '', loadChildren: './components/reservation/reservation.module#ReservationModule'},
        ],
        canActivate: [AuthGuard],
        data: {permissionId: [14]}
    },
    {
        path: 'financial', component: ContentComponent,
        children: [
            {path: '', loadChildren: './components/financial/financial.module#FinancialModule'},
        ],
        canActivate: [AuthGuard],
        data: {permissionId: [18]}
    },
    {
        path: 'report', component: ContentComponent,
        children: [
            {path: '', loadChildren: './components/reports/reports.module#ReportsModule'},
        ],
        canActivate: [AuthGuard],
        data: {permissionId: [21,22,23,24,25,26]}
    },
    {
        path: 'contact', component: ContentComponent,
        children: [
            {path: '', loadChildren: './components/contact/contacts.module#ContactsModule'},
        ],
        canActivate: [AuthGuard],
        data: {permissionId: [27]}
    },
    {
        path: 'support', component: ContentComponent,
        children: [
            {path: '', loadChildren: './components/help-support/help.support.module#HelpSupportModule'},
        ],
        canActivate: [AuthGuard],
        data: {permissionId: [28]}
    },
    {
        path: 'my-account', component: ContentComponent,
        children: [
            {path: '', loadChildren: './components/my-account/my.account.module#MyAccountModule'},
        ]
    },
    {
        path: 'content', component: ContentComponent,
        children: [
            {path: '', loadChildren: './components/content/content.module#ContentModule'},
        ]
    },
    {path: 'activate-sp/:id', component: ActivateSpComponent},

];

export const AppRoutingModule: ModuleWithProviders =
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'});
