import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Location} from '@angular/common';
import {PRIMARY_OUTLET, Router, UrlSegment, UrlSegmentGroup, UrlTree} from '@angular/router';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';
@Injectable()
export class SharedService {
    constructor(private router: Router) {
        const currentState = JSON.parse(localStorage.getItem('currentState'));
        if (currentState && currentState.header) {
            this.serviceProviderMenu.next(currentState.header);
        }
    }

    isUserLoggedIn = false;
    userLoggedIn: Subject<boolean> = new Subject();
    userManual: Subject<boolean> = new Subject();
    comnQues: Subject<boolean> = new Subject();

    private NotificationTabEvent = new BehaviorSubject<boolean>(false);
    currentNotificationTabStatus = this.NotificationTabEvent.asObservable();
    public medicalServiceData = {};
    public fcmToken = new Subject();
    public serviceProviderMenu = new BehaviorSubject<string>('');
    public sideMenu = new BehaviorSubject<string>('');
    public userDisabled = new Subject<any>();
    public userNotifications = new Subject<any>();
    public browserBackButtonPressed = new Subject<any>();

    getSideMenu(): Observable<any> {
        return this.sideMenu.asObservable();
    }

    setSideMenu(value) {
        this.sideMenu.next(value);
        localStorage.setItem('sideMenu', value);
    }

    getMenu(): Observable<any> {
        return this.serviceProviderMenu.asObservable();
    }

    setMenu(value) {
        this.serviceProviderMenu.next(value);
        const currentState = {header: value};
        localStorage.setItem('currentState', JSON.stringify(currentState));
    }

    setNotificationTabStatus(val) {
        this.NotificationTabEvent.next(val);
    }

    getUrlSegment() {
        const tree: UrlTree = this.router.parseUrl(this.router.url);
        const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
        const s: UrlSegment[] = g.segments;
        return s;
    }

    getPreviousPath(endPath) {
        return (this.router.url.split(endPath)).join('');
    }

    getIdFromUrl(segment) {
        const path = this.router.url.split(segment).pop();
        return path.split('/')[1];
    }

    getActiveTab() {
        const path = this.router.url.split('/');
        return path[path.length - 1];
    }

    forcefullyLogoutDisabledUser(): Observable<any> {
        return this.userDisabled.asObservable();
    }

    processUserNotifications(): Observable<any> {
        return this.userNotifications.asObservable();
    }

    getActiveLanguage() {
        return localStorage.getItem('selectedLanguage');
    }

    onBrowserBackButtonPress(): Observable<any> {
        return this.browserBackButtonPressed.asObservable();
    }
    exportpdf(devid) {
        const doc = new jsPDF()
        autoTable(doc, { html: '#' + devid })
        doc.save(devid + '.pdf')
    }

    exportexcel(devid): void {
        /* table id is passed over here */
        let element = document.getElementById(devid);
        const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        XLSX.writeFile(wb, devid + '.xlsx');

    }

    exportprint(devid) {
        const doc = new jsPDF()
        autoTable(doc, { html: '#' + devid })
        doc.save(devid + '.pdf')
    }
}

