/**
 * @Author Irfan Nasim
 * @since 24-Jun-2020
 * @Description Application URL Constants
 */
export class APIURLConstants {

    // Authentication API URL
    public static LOGIN_API_URL = '/core/login';
    public static LOGOUT_API_URL = '/core/logout';
    public static LOGGED_IN_USER_URL = '/core/loggedInUser';
    public static RESEND_OTP_PHONE_NUMBER_API_URL = '/customer/resendOTPViaSMS';
    public static RESEND_ACTIVATION_MAIL_API_URL = '/content/resendActivationEmailSp';

    // Locale API URL
    public static GET_ALL_COUNTRIES_API_URL = '/core/get-countries';
    public static GET_ALL_CITIES_API_URL = '/core/get-cities';
    public static GET_CITIES_WITH_ID_API_URL = '/core/get-city-with-id';
    public static GET_DISTRICT_WITH_ID_API_URL = '/content/getDistrictByCityId/';
    public static GET_BRANCH_COUNTRIES_API_URL = '/admin/branches-countries/';

    // User API URL
    public static CREATE_USER_API_URL = '/core/register';
    public static GET_PAGINATED_USER_ACTIVITY_LOGS = '/admin/activity/all/10?page=$page$';
    public static GET_ADMIN_USER_BY_ID = '/admin/get-admin-user/$id$';
    public static UPDATE_USER_API_URL = '/admin/update-admin-user';
    public static FORGOT_PASSWORD_API_URL = '/core/password/create';
    public static RESET_PASSWORD_API_URL = '/core/password/reset';
    public static RESET_PASSWORD_OTP_API_URL = '/core/sendResetPasswordOTP';
    public static RESET_SP_NUMBER_API_URL = '/core/sp/number/reset';
    public static GET_USER_PERMISSIONS_API_URL = '/user/getAllPermissions';
    public static GET_ALL_ROLES_API_URL = '/user/getAllRoles';
    public static GET_SERVICE_PROVIDER_BY_USER_ID = '/serviceprovider/getServiceProviderByUserId/$id$';
    public static UPDATE_SERVICE_PROVIDER = '/serviceprovider/updateServiceProvider';
    public static ACTIVATE_SERVICE_PROVIDER = '/core/activateSp/';
    public static GET_ROLES_BY_ID_API_URL = '/user/getRolesByServiceProvider';

    // Specialities API URL
    public static GET_TOTAL_SPECIALITIES_API_URL = '/admin/speciality/all/1000?page=$page$';
    public static GET_ALL_SPECIALITIES_WITHOUT_PAGINATION_API_URL = '/admin/speciality/all/1000?page=$page$';

    // change password
    public static CHANGE_PASSWORD_API_URL = '/user/change-password';
    public static CHANGE_PASSWORD_WITH_OTP_API_URL = '/user/changePasswordWithOTP';
    public static SEND_OTP_AT_MOBILE_API_URL = '/customer/resendOTPViaSMS';

    // settings
    public static GET_ALL_BRANCHES_API_URL = '/admin/allBranches ';

    // medical services
    public static GET_TYPE_WISE_SPECIALITIES_API_URL = '/admin/getTypeWiseSpecialities';
    public static GET_SP_MEDICAL_SPECIALITY_LISTING_API_URL = '/admin/getSpMedicalSpecialityListing';
    public static GET_ALL_MEDICAL_SERVICES_WITHOUT_PAGINATION_API_URL = '/admin/medicalService/all/1000?page=$page$';

    public static GET_SPECIALITY_SERVICE_API_URL = '/admin/getMedicalService';
    public static GET_SP_SPECIALITY_SERVICE_API_URL = '/admin/getSPMedicalService';
    public static GET_SPECIALITY_BRANCHES_API_URL = '/admin/getSpecialityBranches';
    public static GET_SP_MEDICAL_SERVICES_LISTING_API_URL = '/admin/getSpMedicalServicesListing';
    public static DELETE_SP_MEDICAL_SERVICES_BY_ID_API_URL = '/admin/deleteSpMedicalServicesById';
    public static GET_MEDICAL_SERVICE_DETAIL_API_URL = '/admin/getMedicalServiceDetails/';
    public static GET_BRANCH_MEDICAL_SERVICES_BY_SPECIALITY_ID_API_URL = '/admin/getBranchMedicalServicesBySpecialityId';
    public static GET_SP_MEDICAL_SERVICES_API_URL = '/admin/spMedicalServices/all/1000/$spId$?page=$page$';
    public static GET_SP_SECONDARY_MEDICAL_SERVICES_API_URL = '/admin/spSecondaryMedicalServices/all/1000/$spId$?page=$page$';
    public static UPDATE_DOCTOR_SERVICE_STATUS_API_URL = '/admin/spMedicalService/updateStatus/';
    public static GET_MEDICAL_SERVICES_DETAIL_BY_ID_API_URL = '/admin/getMedicalServiceById/';

    // Help and Support
    public static GET_CONTACTS_API_URL = '/admin/contactus/all/10?page=$page$';
    public static GET_TICKETS_API_URL = '/admin/ticket/listing/all/10?page=$page$';
    public static SEARCH_TICKETS_API_URL = '/admin/ticket/listing/all/10?page=$page$';
    public static GET_OPEN_TICKETS_API_URL = '/admin/ticket/listing/open/10?page=$page$';
    public static GET_CLOSE_TICKETS_API_URL = '/admin/ticket/listing/close/10?page=$page$';
    public static ADD_TICKETS_API_URL = '/admin/tickets';
    public static GET_TICKET_BY_ID_API_URL = '/admin/getTicket';
    public static CLOSE_TICKET_API_URL = '/admin/tickets';
    public static UPDATE_TICKET_API_URL = '/admin/ticket/update';
    public static GET_SECTION_API_URL = '/admin/sections/all';

    public static UPDATE_USER_MANUAL_API_URL = '/admin/updateUserManual';
    public static GET_USER_MANUAL_API_URL = '/admin/getUserManual/all/10?section_id=';
    public static GET_FAQ_API_URL = '/admin/getFAQ/all/10/service_provider?section_id=';
    public static DELETE_USER_MANUAL_API_URL = '/admin/deleteUserManual';
    public static ADD_FAQ_API_URL = '/admin/addFAQ';
    public static UPDATE_FAQ_API_URL = '/admin/updateFAQ';
    public static DELETE_FAQ_API_URL = '/admin/deleteFAQ';

    // Service Provider Dashboard
    public static SP_DASHBOARD_API_URL = '/serviceprovider/dashboard';
    public static UPDATE_SP_STATUS_API_URL = '/serviceprovider/updateSpStatus';
    public static GET_SP_DETAIL_API_URL = '/serviceprovider/getSpDetails';
    public static GET_SP_DETAIL_BY_ID_API_URL = '/serviceprovider/getServiceProvider/';
    public static BRANCH_DASHBOARD_API_URL = '/serviceprovider/getBranchDashboardDetail/';

    // Branches
    public static GET_SERVICE_PROVIDER_TYPE_API_URL = '/serviceprovider/getServiceProviderTypes';
    public static CREATE_BRANCH_BASIC_INFO_API_URL = '/admin/createBranch';
    public static UPDATE_BRANCH_BASIC_INFO_API_URL = '/admin/updateBranchBasic';
    public static ADD_DISTRICT_APPROVAL = '/serviceprovider/addDistrictApproval';
    public static CREATE_BRANCH_GENERAL_INFO_API_URL = '/admin/createBranchGeneral';
    public static CREATE_BRANCH_GENERAL_INFO_API_URL_TEST = '/admin/imageUploadTest';
    public static CREATE_BRANCH_SPECIALITIES_API_URL = '/admin/createBranchSpecialities';
    public static CREATE_BRANCH_MEICAL_SERVICE_API_URL = '/admin/createMedicalService';
    public static CREATE_BRANCH_PAYMENT_METHOD_API_URL = '/admin/addBranchPaymentMethod';
    public static CREATE_CONTACT_API_URL = '/admin/createContact';
    public static GET_CONTACT_API_URL = '/admin/getContacts';
    public static DELETE_CONTACT_API_URL = '/admin/deleteContact';
    public static UPDATE_CONTACT_API_URL = '/admin/updateContact';
    public static GET_SPECIALITY_BY_BRANCH_ID_API_URL = '/admin/getSpecialitiesByBranchId';
    public static GET_BRANCH_BY_SP_API_URL = '/admin/getBranchesBySP';
    public static GET_BRANCH_DETAIL_BY_ID_URL = '/admin/getBranchDetailById';
    public static GET_PAGINATED_BRANCHES_API_URL = '/admin/branches/all/10/$spId$?page=$page$';
    public static UPDATE_BRANCH_STATUS_URL = '/admin/makeBranchCreationRequest';
    public static UPDATE_REQUEST_CREATE_BRANCH_STATUS_URL = '/admin/requestBranchCreation';
    public static GET_BRANCH_RESERVATIONS_API_URL = '/admin/branchReservationListing/10';
    public static GET_NEIGHBORHOOD_BRANCH_API_URL = '/admin/getNeighborhoodBranch/';
    public static SPECIALITY_CREATION_REQUEST = '/admin/specialityCreationRequest/';
    public static UPDATE_BRANCH_V1_STATUS_URL = '/admin/updateBranchStatusV1';
    public static UPDATE_DOCTOR_STATUS_URL = '/admin/updateDoctorStatus';

    // Doctors
    public static GET_DOCTOR_TITLE_API_URL = '/admin/getDoctorTitles';
    public static GET_DOCTOR_NATIONALITY_API_URL = '/admin/getDoctorNationalities';
    public static GET_DOCTOR_TREATMENT_API_URL = '/serviceprovider/getTreatments';
    public static GET_SP_BRANCHES_API_URL = '/admin/getBranchesBySP';
    public static CREATE_DOCTOR_API_URL = '/admin/createDoctor';
    public static GET_DOCTORS_API_URL = '/admin/doctors/all/10/$spId$?page=$page$';
    public static GET_DOCTORS_SP_API_URL = '/admin/spDoctors/list/10/$spId$?page=$page$';
    public static GET_ALL_DOCTORS_API_URL = '/admin/doctors/all/1000/$spId$?page=$page$';
    public static GET_DOCTOR_SERVICES_API_URL = '/admin/doctorServices/all/10/$spId$?page=$page$';
    public static DELETE_DOCTOR_SERVICE_API_URL = '/admin/deleteDoctorService';
    public static GET_DOCTOR_DETAIL_API_URL = '/admin/getDoctorDetails';
    public static GET_DOCTOR_BRANCHES_API_URL = '/admin/getDoctorBranches';
    public static UPDATE_DOCTOR_API_URL = '/admin/updateDoctor';
    public static GET_LANGUAGES_API_URL = '/serviceprovider/getLanguages';
    public static GET_DOCTOR_RESERVATIONS_API_URL = '/admin/doctorReservationListing/$status$/10';
    public static UPDATE_DOCTOR_MEDICAL_SERVICES_API_URL = '/admin/updateDoctorServices/';
    public static GET_DOCTOR_SERVICE_BY_BRANCH_AND_SPECIALITY_API_URL = '/admin/getDoctorServices';
    public static GET_DOCTOR_CONSULTATION_BY_BRANCH_AND_SPECIALITY_API_URL = '/admin/getDoctorConsultation';
    public static GET_DOCTOR_SPECIALITIES_BY_BRANCH_API_URL = '/admin/getDoctorSpecialities';
    public static GET_DOCTOR_RATINGS_BY_DOC_ID_API_URL = '/admin/getDoctorRatings/10/$docId$?page=$page$&dateFrom=$dateFrom$&dateTo=$dateTo$&rating=$rating$';
    public static GET_All_DOCTORS_API_URL = '/admin/doctors/all/1000/$spId$?page=$page$';
    public static GET_SP_SPECIFIC_DOCTOR_TITLE_API_URL = '/admin/getSpSpecificDoctorTitles';
    public static SEND_DOCTOR_REGISTRATION_INVITATION = '/admin/sendRegistrationInvitation/';
    public static SEND_USER_INVITATION = '/serviceprovider/sendUserInvitation';
    public static CANCEL_USER_INVITATION = '/serviceprovider/cancelInvite';
    public static UPDATE_DOCTOR_CONSULTANCY_API_URL = '/admin/updateDoctorConsulting/';

    // Doctor Specialties
    public static GET_BRANCH_SPECIALITY_API_URL = '/admin/getSpecialitiesByBranchId';
    public static CREATE_DOCTOR_CONSULTANCY_API_URL = '/admin/createDoctorConsulting';
    public static CREATE_DOCTOR_SERVICE_API_URL = '/admin/createDoctorServices';
    public static GET_DOCTOR_BY_SP_API_URL = '/admin/getDoctorsByServiceProvider';
    public static UPDATE_MEDICAL_SERVICE_STATUS_API_URL = '/admin/updateDoctorServicesStatus/';

    // Doctor Schedule
    public static GET_SHOW_DOCTOR_SCHEDULE_API_URL = '/admin/showDoctorSchedule/';
    public static GET_DOCTOR_SCHEDULES_API_URL = '/admin/doctorScheduleListing/all/10/$spId$?page=$page$';
    public static CREATE_DOCTOR_SCHEDULE_API_URL = '/admin/createDoctorSchedule';
    public static GET_FILTERS_BY_DOCTOR_API_URL = '/admin/getFiltersByDoctorId';
    public static VIEW_DOCTOR_SCHEDULE_API_URL = '/admin/viewDoctorDetailsById/';
    public static DELETE_DOCTOR_SCHEDULE_API_URL = '/admin/deleteDoctorDetailsById/';
    public static GET_SHOW_CENTER_SCHEDULE_API_URL = '/admin/showCenterSchedule/';
    public static EDIT_DOCTOR_SCHEDULE_API_URL = '/admin/editDoctorSchedule';

    // Center Schedule
    public static CREATE_SPECIALITIES_TYPE_AND_SP_WISE_API_URL = '/admin/getSpecialitiesBySpAndType';
    public static GET_BRANCHES_BY_SERVICE_API_URL = '/admin/getBranchesByServiceId';
    public static GET_BRANCHES_AND_SERVICES_BY_SPECIALITY_ID_API_URL = '/admin/getBranchesAndServicesBySpecialityId';
    public static CREATE_CENTER_SCHEDULE_API_URL = '/admin/createCenterSchedule';
    public static GET_CENTER_SCHEDULES_API_URL = '/admin/centerScheduleListing/all/10/$spId$?page=$page$';
    public static EDIT_CENTER_SCHEDULE_API_URL = '/admin/editCenterSchedule';
    public static VALIDATE_CENTER_SERVICES_API_URL = '/admin/validateCenterBranchServices';
    // offers
    public static GET_SPECIALITIES_BY_SP_API_URL = '/admin/getSpecililtiesBySP';
    public static CREATE_OFFER_API_URL = '/admin/createOffers';
    public static GET_ALL_OFFERS_API_URL = '/admin/offers/all/10/$spId$?page=$page$';
    public static CREATE_RESERVATION_API_URL = '/admin/createReservations';
    public static GET_OFFER_DETAIL_API_URL = '/admin/getOfferDetail';
    public static UPDATE_OFFER_STATUS_API_URL = '/admin/updateOfferStatus/';
    public static CREATE_SPECIAL_OFFER_API_URL = '/admin/createSpecialOffer';
    public static GET_SPECIAL_OFFER_API_URL = '/admin/specialOfferListing';
    public static GET_SHOW_OFFER_SCHEDULE_API_URL = '/admin/showOfferReservation/';
    public static GET_OFFER_RESERVATIONS_API_URL = '/admin/offerReservationListing/10/';
    public static DESIGN_REQUEST_OFFER_API_URL = '/admin/designRequest/';
    public static GET_OFFER_DESIGN_REQUEST_API_URL = '/admin/getOfferDesignRequests/';
    public static GET_OFFER_REVIEWS_BY_ID_API_URL = '/admin/getOfferReviews/';
    public static GET_BRANCH_REVIEWS_BY_ID_API_ULR = '/admin/getBranchReviews/';
    public static EDIT_OFFER_API_URL = '/admin/editSPOffers';
    public static EDIT_RESERVATION_API_URL = '/admin/editReservations';
    public static GET_OFFER_DESIGN_REQUEST_SETTING_API_URL = '/admin/get-offer-design-request-setting/';

    // Service Provider
    public static GET_ALL_SP_API_URL = '/admin/service_providers/all/1000?page=$page$';

    // SP Medical Services
    public static GET_PAGINATED_SP_MEDICAL_SERVICES_API_URL = '/admin/spMedicalServices/all/10/$spId$?page=$page$';
    public static GET_PAGINATED_SP_MEDICAL_SERVICES_WITHOUT_BRANCH_API_URL = '/admin/spMedicalServicesV1/all/10/$spId$?page=$page$';
    public static UPDATE_SP_MEDICAL_SERVICES_API_URL = '/admin/updateMedicalService/';
    public static REQUEST_SP_MEDICAL_SERVICES_CREATE_API_URL = '/admin/requestSpMedicalServiceCreate';
    public static GET_SP_MEDICAL_SERVICES_DETAIL_API_URL = '/admin/getSpMedicalServiceDetailById/';

    // SP Users
    public static GET_PAGINATED_SP_USERS_API_URL = '/admin/service_provider_users/all/10/$spId$?page=$page$';
    public static GET_SP_USERS_POSITION_API_URL = '/user/getAllRoles';
    public static GET_SP_USER_SEARCH_RESULT_API_URL = '/admin/autoCompleteServiceProviderUsers';
    public static GET_SP_USER_DETAILS_API_URL = '/admin/getServiceProviderUserDetails';
    public static UPDATE_SP_USER_STATUS_API_URL = '/admin/updateUserStatus';
    public static UPDATE_SP_USER_PERMISSION_API_URL = '/admin/updateSpUserPermission';
    public static GET_DOCTOR_PERMISSION_API_URL = '/admin/getDoctorPermissions';
    public static UPDATE_SP_USER_API_URL = '/admin/updateSpUser';
    public static GET_SP_USER_INVITATIONS_API_URL = '/user/getUserInvitations/';

    // Customer
    public static GET_ALL_CUSTOMER_API_URL = '/customer/customerListing/all/1000?page=$page$';

    // reservation
    public static GET_RESERVATIONS_API_URL = '/admin/reservation/$status$/10?page=$page$';
    public static UPDATE_RESERVATION_STATUS_API_URL = '/admin/updateReservationStatus/';
    public static GET_RESERVATION_DETAIL_BY_ID_API_URL = '/admin/getReservationDetailById/';
    public static CANCEL_RESERVATION_BY_ID_API_URL = '/admin/updateReservationStatus/';
    public static GET_DOCTOR_BY_BRANCH_SPECIALITY_ID_API_URL = '/admin/getDoctorsByBranchIdAndSpeciality/';
    public static UPDATE_RESERVATION_API_URL = '/admin/updateReservation/';
    public static GET_RESERVATIONS_V1_API_URL = '/admin/reservationV1/$status$/10?page=$page$';
    public static GET_RESERVATIONS_EXPORT_API_URL = '/admin/reservationExport/$status$?';

    // Financial API URL
    public static GET_FINANCIAL_ALL_API_URL = '/financial/listing/all/10?page=$page$&from_date=$fromDate$&to_date=$toDate$';
    public static CREATE_BANK_ACCOUNT_API_URL = '/financial/saveBankAccount';
    public static CREATE_SP_BANK_ACCOUNT_API_URL = '/financial/saveSpBankAccount';
    public static UPDATE_BANK_ACCOUNT_API_URL = '/financial/updateBankAccount/';
    public static UPDATE_SP_BANK_ACCOUNT_API_URL = '/financial/updateSpBankAccount/';
    public static GET_ALL_BANK_ACCOUNT_API_URL = '/financial/bank/all';
    public static GET_SP_BANK_ACCOUNT_API_URL = '/financial/SpBankAccount/';
    public static GET_FINANCIAL_ENTRY_BY_ID_API_URL = '/financial/getFinancialEntryDetailById/';
    public static GET_SP_COUNTRIES_API_URL = '/serviceprovider/getSpCountries';
    public static GET_SP_FINANCIAL_LISTING_API_URL = '/financial/sp/settlement/detail/list/10?page=$page$&from_date=$fromDate$&to_date=$toDate$&sp_id=$sp_id$';
    public static GET_SP_FINANCIAL_SETTLEMENT_ALL_API_URL = '/financial/sp/settlement/listing/all/10';
    public static GET_SP_FINANCIAL_PREVIOUS_SETTLEMENT_ALL_API_URL = '/financial/sp/pre/settlement/listing/all/10';
    public static GET_FINANCIAL_UNPAID_INVOICES_ALL_API_URL = '/financial/invoices/unpaid-listing';
    public static GET_FINANCIAL_UNPAID_INVOICES_DETAIL_ALL_API_URL = '/financial/consolidated/invoices/unpaid-listing/';
    public static GET_FINANCIAL_PAID_INVOICES_ALL_API_URL = '/financial/invoices/paid-listing';
    public static GET_FINANCIAL_MAKE_PAYMENT_API_URL = '/financial/sp/pay-invoice';
    public static GET_FINANCIAL_MAKE_PAYMENT_REQUEST_API_URL = '/financial/sp_payment_request';
    public static GET_FINANCIAL_INVOICE_DETAIL_API_URL = '/financial/invoice/detail/';
    public static GET_FINANCIAL_INVOICE_RESERVATION_LISTING_API_URL = '/financial/invoice/reservation/listing/';

    // Dashboard API URL
    public static DASHBOARD_API_URL = '/serviceprovider/dashboard';

    // Content Pages API URL
    public static GET_ABOUT_US_API_URL = '/content/getAboutMedicta/';
    public static GET_NEW_CONTENT_API_URL = '/content/getNewContent/';
    public static GET_PARTNERS_API_URL = '/content/getPartners/100';
    public static GET_CAREERS_API_URL = '/career/getUnexpiredCareers';
    public static GET_FAQS_API_URL = '/content/getFaq/100/service_provider';
    public static GET_TERMS_AND_CONDITION_API_URL = '/content/getTermsAndCondition/';
    public static GET_PRIVACY_POLICY_API_URL = '/content/getPrivacyPolicy/';
    public static GET_RESERVATION_CANCELATION_API_URL = '/content/getReservationAndCancellationPolicy/';
    public static GET_PAYMENT_METHODS_API_URL = '/content/getPaymentMethods/';
    public static GET_CONTACT_INFO_API_URL = '/content/getContactInfo';
    public static GET_CONTRACTS_API_URL = '/admin/getContracts/10?status=active';

    // Financial Invoices
    public static GET_FINANCIAL_INVOICE_LISTING_URL = '/financial/invoices/listing';
    public static GET_FINANCIAL_INVOICE_LISTING_DETAIL_URL = '/financial/invoices/listingDetail/';
    public static GET_FINANCIAL_INVOICE_SUB_MENU_LISTING_DETAIL_URL = '/financial/invoices/details/listing';
    public static GET_FINANCIAL_INVOICE_META_DETAIL_URL = '/financial/invoices/metaDetail/';

    // Push Notification
    public static CLEAR_ALL_NOTIFICATIONS = '/user/clearAllNotifications/';
    public static GET_ALL_NOTIFICATIONS = '/user/notifications/all/';
    public static GET_ALL_NOTIFICATIONS_COUNT = '/user/notifications/count/';
    public static GET_ALL_NOTIFICATIONS_SEE_ALL = '/user/notifications/listing/'

    // Reports API
    public static REPORTS_GET_ALL_FILTERS = '/report/filters'
    public static REPORTS_GET_ALL_BRANCH = '/report/branches/'
    public static REPORTS_GET_ALL_DOCTORS = '/report/doctors/'
    public static REPORTS_GET_ALL_MS = '/report/medical-services/'
    public static REPORTS_GET_ALL_RESERVATION = '/report/reservations/'
    public static REPORTS_GET_ALL_OFFER = '/report/offers/'
    public static REPORTS_GET_ALL_FINANCIAL = '/report/financial/'


}
