import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AdminUtilService } from './service/admin-util.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(public adminUtilService: AdminUtilService, public router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(next.data.permissionId.length){
      for (const id of next.data.permissionId) {
        if (!this.adminUtilService.userHasPermission(id)) {
          if(this.adminUtilService.userInfo){
            this.router.navigate(["dashboard"])
          }else{
            this.router.navigate([""])
          }
          return false;
        }
      }
    }
    return true
  }

}
