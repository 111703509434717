import {Injectable, ElementRef} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LoaderService} from './loader.service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';


const PDF_EXTENSION = '.pdf';
const EXCEL_EXTENSION = '.xlsx';


@Injectable()
export class ExportService {

    constructor(private http: HttpClient,
                public loaderService: LoaderService) {
    }


    exportPdf(headerData, bodyData, filename) {
        const doc = new jsPDF();

        const columns = headerData;
        const data = bodyData;
        const fileName = filename;

        autoTable(doc, {
            head: columns,
            body: data,
            didDrawPage: (dataArg) => {
                // doc.setFont('times', '', 'normal');
                doc.text(fileName, dataArg.settings.margin.left, 10);

                doc.setProperties({
                    title: 'Medicta',
                    author: 'Medicta'
                });
            },
            headStyles: {
                fillColor: '#6ab130',
                fontSize: 8,
            },
            bodyStyles: {
                fontSize: 7,
            }
        });

        doc.save(fileName + '_export_' + new Date().getTime() + PDF_EXTENSION);
    }

    exportExcel(headerData, bodyData, filename) {

        const edata = this.setExcelFileData(headerData, bodyData);

        const worksheet = XLSX.utils.json_to_sheet(edata);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, filename);
    }

    saveAsExcelFile(buffer: any, fileName: string): void {
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }

    setExcelFileData(head, body) {
        const fileData = [];
        body.forEach((v) => {

            const rowData = [];
            head.forEach((key, i) => {
                rowData[key] = v[i];
            });

            fileData.push(rowData);
        });

        return fileData;
    }
}
