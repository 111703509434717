import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {RequestsService} from '../../service/requests.service';
import {Router, ActivatedRoute} from '@angular/router';
import {SharedService} from '../../service/shared.service';
import {AdminUtilService} from '../../service/admin-util.service';
import {MessageService} from 'primeng';
import {TranslateService} from '@ngx-translate/core';
import {APIURLConstants} from '../../util/api.url.constants';
import {NgForm} from '@angular/forms';
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'app-change-password',
    templateUrl: '../../templates/change-password/change-password.component.html',
    styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

    newPassword: string;
    confirmPassword: string;
    forgotPasswordModal: boolean;
    isTokenValid = false;
    token = '';
    email: '';

    constructor(private requestsService: RequestsService,
                private router: Router,
                public sharedService: SharedService,
                public adminUtilService: AdminUtilService,
                private elementRef: ElementRef,
                private toastrService: ToastrService,
                public messageService: MessageService,
                private translate: TranslateService,
                private activatedRoute: ActivatedRoute) {
    }

    ngOnInit() {
        this.token = this.activatedRoute.snapshot.params.id;
        this.requestsService.getRequest('/core/password/verifyTokenExpiry/' + this.token).subscribe(
            (response) => {
                if (response['responseCode'] === 'INVITATION_ERR_07') {
                    this.toastrService.error(response['responseMessage'], response['responseStatus']);
                }
                else if (response['responseStatus'] === 'SUCCESS') {
                    this.isTokenValid = true;
                    this.email = response['responseData'][0].email;
                }
            }
        );
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#dbeaca';
    }

    ngOnDestroy() {
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#fff';
    }
    confirmPasswordCheck(){
        if(this.confirmPassword === this.newPassword) return false
        else return true
    }
    confirm(form: NgForm) {
        if (form.valid) {
            if(this.confirmPasswordCheck()) {
                this.toastrService.error("Password and Confirm password must be matched", 'Mismatch Password!');
                return
            }
            if (this.isTokenValid) {
                this.requestsService.postRequest(
                    APIURLConstants.RESET_PASSWORD_API_URL
                    , {
                        email: this.email,
                        password: this.newPassword,
                        password_confirmation: this.confirmPassword,
                        token: this.token
                    })
                    .subscribe(
                        (response: Response) => {
                            if (response['responseStatus'] === 'SUCCESS') {
                                this.toastrService.success(response['responseMessage'], 'Password Reset');

                                setTimeout(() => {
                                    this.router.navigate(['/login']);
                                }, 500);
                            }

                        });
            }
            else
            {

            this.messageService.add({
                key: 'loginToast',
                severity: 'error',
                summary: 'Change Password',
                detail: 'Token is Invalid.'
            });
            }
        } else {

            this.messageService.add({
                key: 'loginToast',
                severity: 'error',
                summary: 'Change Password',
                detail: 'Fields are required.'
            });
        }
    }

    cancel() {
        this.router.navigate(['./login']);
    }

    changeLanguage(language: string) {
        this.translate.use(language);
    }

}
